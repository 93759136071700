import React, { useEffect, useState } from 'react';
import Header from './Header';
import NavBar from './NavBar';
import { App, main, content } from '../styles/layout.module.css';
import { AppJor } from '../styles/jordan.module.css';
import { GlitchContext } from './juniper/Tabs/Campaigns/JordanMode/Glitchcon';
import GlitchClip from 'react-glitch-effect/core/GlitchClip';
import JIRAIssueCollector from './JIRAIssueCollector';
import { logout } from '../utils/Auth';

function Layout({ children, noLayout }) {
  const [isDisabled, setDisabled] = useState(true);

  const handleToggleGlitch = () => {
    setDisabled(!isDisabled);
  };

  if (noLayout) {
    return <div className={AppJor}>{children} </div>;
  } else
    return (
      <div className={App}>
        <GlitchClip disabled={isDisabled} duration={25}>
          <div className={main}>
            <NavBar />
            <div className={content}>
              <GlitchContext.Provider value={{ isDisabled, handleToggleGlitch, setDisabled }}>
                <Header />

                {/* Bug Collector */}
                {!noLayout && (
                  <JIRAIssueCollector
                    srcUrl={
                      'https://springoakscapital.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3o5b4z/b/5/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=5bb4e8d5'
                    }
                  />
                )}
                {children}
              </GlitchContext.Provider>
            </div>
          </div>
        </GlitchClip>
      </div>
    );
}

export default Layout;

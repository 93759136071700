const sha256 = async (str) => {
  return await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
};

const generateNonce = async () => {
  const hash = await sha256(
    crypto.getRandomValues(new Uint32Array(4)).toString()
  );
  const hashArray = Array.from(new Uint8Array(hash));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
};

const base64URLEncode = (string) => {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
};

/*Stores codeVerifier to local storage to compare with response from login/cognito, not token yet*/
const redirectToLogin = async () => {
  if (typeof window !== `undefined`) {
    const state = await generateNonce();
    const codeVerifier = await generateNonce();
    sessionStorage.setItem(`codeVerifier-${state}`, codeVerifier);
    const codeChallenge = base64URLEncode(await sha256(codeVerifier));
    window.location = `https://heartwood.auth.us-east-2.amazoncognito.com/login?client_id=7t99qqeffatj32hgniqrka348j&response_type=code&state=${state}&code_challenge_method=S256&code_challenge=${codeChallenge}&scope=email%20openid%20phone&redirect_uri=${process.env.GATSBY_REDIRECT}/login`;
  }
};

const logout = () => {
  if (typeof window !== `undefined`) {
    document.cookie = "id_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    window.location = process.env.GATSBY_REDIRECT;
    console.log("Logged out");
  }
};

const decodeJWT = (token) => {
  if (typeof window !== `undefined`) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
};

export { redirectToLogin, logout, decodeJWT };

import React from 'react';
import { logout } from '../../utils/Auth';

function Timeout() {
  function checkTime() {
    const currentTime = new Date();
    const cookieExpiration = new Date(localStorage.getItem('id_token_expiration'));
    const delay = cookieExpiration - currentTime;

    if (delay <= 0) {
      logout();
    }
  }
  setInterval(checkTime, 10000);
}
//use in login page
export default Timeout;
